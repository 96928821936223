// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
@import "~angular-vertical-timeline/dist/vertical-timeline.css";

//@import "~material-icons/iconfont/material-icons.css";

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v79/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2) format('woff2');
}

$lg-logistic-primary-palette: (
  50 : #e8f3fa,
  100 : #c6e2f3,
  200 : #a0cfec,
  300 : #79bbe4,
  400 : #5dadde,
  500 : #409ed8,
  600 : #3a96d4,
  700 : #328cce,
        800 : #2a82c8,
        900 : #1c70bf,
        A100 : #f5faff,
        A200 : #c2e0ff,
        A400 : #8fc6ff,
        A700 : #75b8ff,
        contrast: (
                50 : #ffffff,
                100 : #ffffff,
                200 : #ffffff,
                300 : #ffffff,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #ffffff,
                A200 : #ffffff,
                A400 : #ffffff,
                A700 : #ffffff,
        )
);

$lg-logistic-warn-palette: (
        50 : #ffeee8,
        100 : #ffd4c6,
        200 : #ffb7a0,
        300 : #ff9a7a,
        400 : #ff855e,
        500 : #ff6f41,
        600 : #ff673b,
        700 : #ff5c32,
        800 : #ff522a,
        900 : #ff401c,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffd4cd,
        A700 : #ffbdb3,
        contrast: (
                50 : #ffffff,
                100 : #ffffff,
                200 : #ffffff,
                300 : #ffffff,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #ffffff,
                A200 : #ffffff,
                A400 : #ffffff,
                A700 : #ffffff,
        )
);

$lg-logistic-accent-palette: (
        50 : #fff9e8,
        100 : #fff0c6,
        200 : #ffe6a0,
        300 : #ffdb7a,
        400 : #ffd45e,
        500 : #ffcc41,
        600 : #ffc73b,
        700 : #ffc032,
        800 : #ffb92a,
        900 : #ffad1c,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffeccd,
        A700 : #ffe2b3,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #000000,
                700 : #000000,
                800 : #000000,
                900 : #000000,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$lg-logistic-frontend-primary: mat-palette($lg-logistic-primary-palette);
$lg-logistic-frontend-accent: mat-palette($lg-logistic-primary-palette);
$lg-logistic-frontend-warn: mat-palette($lg-logistic-warn-palette);

// Create the theme object (a Sass map containing all of the palettes).
$lg-logistic-frontend-theme: mat-light-theme($lg-logistic-frontend-primary, $lg-logistic-frontend-accent, $lg-logistic-frontend-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($lg-logistic-frontend-theme);

/* You can add global styles to this file, and also import other style files */

:root {
  --main-background-color: #F0F0F0;
  --secondary-color: #F4F9FD; // Вторичный (пример: фон товаров на order-page)
  --success-color: #67C23A; // Основной зеленый (пример: цвет progress-bar)
  --primary-color: #409ED8; // Основной синий
  --danger-color: #F13939; // Основной красный
  --danger-text-color: #ff6f41; // Основной красный
  --muted-color: #757575; // Основной серый (типа для disabled)
  --primary-light: #e3f2fb; // Подсветка синяя (пример: выделение строк в таблице)
  --danger-light: #ffdfd4; // Подсветка красная (пример: для выделения ошибое)
  --muted-light: #EFEFEF; // Подсветка серая (пример: disabled consumers в DEV панели)
  --app-list-border: #66666666; // border app-list'а
  --app-layout-border: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
  1px 1px 0 rgba(0, 0, 0, 0.14),
  0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

html,
mat-sidenav-container {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: var(--main-background-color);
  position: relative;
  height: auto;
  overflow-x: hidden;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;

}

.success-color {
  color: var(--primary-color);
  background-color: white;
}

.error-color {
  background-color: var(--danger-color);
  color: white;
}

.success-color span,
.error-color span {
  display: grid;
  width: 100%;
  justify-content: center;
}
